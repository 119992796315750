/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/mixins";

@import "./theme/variables.sashimi";
@import "node_modules/flag-icon-css/sass/flag-icon.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: AppFont, sans-serif;
  color: #3f3f3f;
}

app-impressum,
app-tos,
app-scan-qr,
app-privacy {
  ion-header {
    background: var(--ion-color-main);
  }
}

ion-content {
  --background: transparent;
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.ion-page {
  background: #ffffff;
}

.place-items-center {
  place-items: center !important;
}

.white {
  fill: white;
  color: white;
}

.black {
  fill: black;
  color: black;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative;
}

.grey {
  fill: #878787;
  color: #878787;
}

.dark {
  fill: var(--ion-color-black);
  color: var(--ion-color-black);
}

app-venue-suggestion {
  display: block;
}

app-sign-in,
app-sign-in > ion-content > main,
app-sign-in-order,
app-sign-in-order > ion-content > main,
app-sign-up,
app-sign-up > ion-content > main,
app-maintenance-page,
app-maintenance-page > ion-content > main {
  background: white !important;

  @include desktop {
    background: white !important;
    background-size: cover !important;

    ion-header {
      ion-row {
        background: transparent !important;
      }
    }
  }

  ion-header {
    background: transparent;
    @include desktop {
      background: transparent;
    }
  }
}

app-account {
  ion-header {
    background: var(--ion-color-main);
  }
}
app-payment-success {
  background: white url("./assets/sashimi/payment_background.png") no-repeat top center / 100% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-multiple-order {
  background: url("/assets/mado/image-mult.png") no-repeat center top / 100% !important;
  @include desktop {
    background: url("/assets/mado/toolbar-desktop.png") no-repeat center top / 10% !important;
    background-size: 100% 30vh !important;
  }
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-menu,
app-menu > ion-content {
  --background: #fdfdfd !important;
}
app-menu > ion-header {
  --background: transparent;
  background: transparent;
}

.card-bg {
  height: 100%;
  background: #f5f5f5 url(/assets/icon/kreditcartepic.svg) right bottom no-repeat !important;
}

.main-color {
  fill: var(--ion-color-main);
  color: var(--ion-color-main);
}

.second-color {
  fill: var(--ion-color-second);
  color: var(--ion-color-second);
}

.gray {
  fill: var(--ion-color-gray-darkest);
  color: var(--ion-color-gray-darkest);
}

.invisible {
  opacity: 0;
}

.flex {
  display: flex;
}

ion-footer {
  background: var(--ion-color-primary);
  color: #fff;
  font-family: AppFontSemiBold, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 20px 10px !important;
  transition: 0.3s linear;
  &::before {
    display: none;
  }

  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-primary);
  }

  span,
  p {
    font-family: AppFontMedium;
    font-size: 16px;
    color: #fff;
  }
}

.ion-footer {
  background: var(--ion-color-primary);
  color: #fff;
  font-family: AppFontSemiBold, sans-serif;
  font-size: 16px;
  border-radius: 28px;
  text-transform: uppercase;
  padding: 15px 10px !important;
  transition: 0.3s linear;
  text-align: center;
  &:hover {
    transition: 0.3s linear;
    background: var(--ion-color-primary);
  }

  span {
    font-family: AppFontMedium;
    font-size: 16px;
    color: #fff;
  }
}

.ion-footer.invalid {
  background: darkgrey;
  color: grey;

  &:hover {
    background: darkgrey;
    color: grey;
  }
}

.bold {
  font-family: AppFont-bold, sans-serif;
  font-weight: normal;
}
.font-max-bold {
  font-family: AppFont-black, sans-serif;
  font-weight: normal;
}

.menu-row ion-icon .icon-inner svg path {
  fill: red !important;
}

.main-bg.active {
  background: var(--ion-color-main);
}

.second-bg.active {
  background: var(--ion-color-second) !important;
}

.input-group {
  margin-bottom: 10px;
  position: relative;

  .eye-icon {
    position: absolute;
    right: 15px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    z-index: 9999;
  }

  &__label {
    margin: 0 0 5px 10px;
    font-size: 12px;
    color: var(--ion-color-second);
  }

  &__input {
    input {
      color: var(--ion-color-gray-darkest) !important;
      border-radius: 0px !important;
      background: white !important;
      padding: 10px !important;
      font-size: 14px !important;
      height: 50px;
    }
  }
}

.padding-auth {
  padding: 30px 26px !important;
  @include desktop {
    padding: 0 !important;
    height: 100%;
  }
}

.auth-wrapper {
  width: 100%;
  @include desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.product-info {
  .action-sheet-group {
    height: 300px;
    padding: 32px 30px !important;

    .action-sheet-title {
      font-family: AppFont-bold, sans-serif;
      color: #333333;
      font-size: 26px;
      padding: 0;

      .action-sheet-sub-title {
        font-family: AppFont, sans-serif;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.auth-container {
  > ion-col {
    display: block;
  }

  @include desktop {
    width: 400px;
    margin: 0 auto;
    padding: 32px 40px;
    background: transparent;
    border-radius: 0;
  }
}

ion-fab-button {
  --background: transparent !important;
}

.hours-modal {
  @include desktop {
    ion-backdrop {
      background: #000000 !important;
    }
  }
}

.modal-wrapper {
  min-height: 85vh !important;
  @include desktop {
    --width: 450px;
  }
  box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.08), 0px -1px 4px rgba(33, 33, 33, 0.04);
  border-radius: 10px;
}

.product-modal {
  .modal-wrapper {
    @include desktop {
      width: 450px !important;
      height: auto !important;
    }
  }
}

.hours-modal .modal-wrapper {
  height: calc(100% - 65px);
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    height: 600px;
    width: 480px;
    position: unset;
    bottom: unset;
  }
}

.short-info-modal {
  background: rgba(0, 0, 0, 0.2);
}

.short-info-modal .modal-wrapper {
  @include desktop {
    width: 450px;
  }
  @include mobile {
    width: 85vw;
  }
  box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.08), 0px -1px 4px rgba(33, 33, 33, 0.04);
  border-radius: 10px;
}

.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-main);
}

.ion-color-white {
  color: #fff;
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.shadow-small {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.google-search-input {
  input {
    font-size: 15px !important;
    padding: 20px 0 !important;
  }
}

.menu-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .popover-content {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    @include desktop {
      width: 315px;
      height: auto;
      min-height: auto;
      top: 75px !important;
      left: unset !important;
      right: 12px !important;
    }
  }

  .popover-arrow {
    right: 20px !important;

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.home-popover {
  font-family: AppFont-bold, sans-serif;

  .popover-content {
    width: 285px;
    border-radius: 0;
    left: 50px !important;
    top: 58px !important;
    @include desktop {
      top: 68px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }

  .popover-arrow {
    left: 70px !important;
    top: 50px !important;

    @include desktop {
      left: -220px !important;
      top: 60px !important;
      right: 0;
      margin: auto;
    }

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.step-heading {
  font-family: AppFontBold, sans-serif;
  font-size: 22px;
  text-align: left;
  padding: 0px 25px;
  line-height: 21px;
  color: var(--ion-color-black);
  margin: 0px 0px 15px;
  width: 100%;
  background: #ffffff;
  text-transform: uppercase;
}

.small-padding {
  .step-heading {
    font-size: 16px;
    margin: 0 0 5px;
  }
}

.position-relative {
  position: relative !important;
}

.height-auto {
  height: auto !important;
}

.pac-container {
  background: #f7f9f9 !important;
  border: 0.5px solid #d5dddd !important;
  width: 100%;
  border-radius: 7px;
  padding: 8px 20px !important;
  margin-top: 5px;
  .pac-item {
    border-top: none !important;
    font-family: AppFont, sans-serif;
    padding: 16px 0;
    line-height: 1 !important;
    font-size: 14px !important;
    border-bottom: 0.5px solid #d5dddd !important;
    text-align: left !important;
    color: var(--ion-colormain);
    &:last-child {
      border-bottom: none;
    }

    .pac-icon {
      display: none !important;
    }

    .pac-matched {
      font-family: AppFontBold, sans-serif;
    }
  }
}

.preorder-type {
  background: var(--ion-color-secondary);
  font-size: x-small;
  height: auto;
  margin: 0 0 0 10px;
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
}

ion-checkbox {
  --background-checked: var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --checkmark-color: white;
}

app-toolbar {
  min-height: 70px;
}

app-toolbar-small {
  min-height: 70px;
}

.toolbar-title {
  font-family: AppFont-black, sans-serif;
  padding-left: 65px;
  text-align: left;
}

.pointer {
  cursor: pointer !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
  input {
    cursor: pointer !important;
  }
}

.auth-padding-small {
  padding: 22px 26px;
  position: relative;
  height: 100%;

  @include desktop {
    padding: 0;
  }

  h1 {
    font-size: 26px;
    line-height: 120%;
    margin: 0 0 15px;
  }

  p {
    font-size: 14px;
    line-height: 145%;
    margin: 0;
  }

  .subway-btn {
    position: absolute;
    width: calc(100% - 52px);
    left: 26px;
    bottom: 26px;
    margin: 0;
  }
}

ion-row.center {
  justify-content: center;
  height: 100%;
  place-content: center;
}

ion-item > ion-label {
  white-space: unset !important;
  overflow: unset !important;
}
ion-item {
  --background: white;
  --border-radius: 5px;
  &.input {
    --border-color: #e6e7e3;
    --border-width: 1px !important;
  }

  ion-input {
    font-size: 14px;
    --placeholder-color: #bfc1bc;
    --placeholder-opacity: 1;
    --padding-start: 10px;
  }

  &.item-has-focus,
  &.item-has-value {
    --border-width: 0;
  }

  > ion-select {
    width: 100%;
    margin-right: 0px;
    background: transparent;
    height: 100%;
    text-align: left;
  }

  > ion-icon {
    color: var(--ion-color-primary);
  }

  > ::slotted([slot="end"]) {
    margin-inline-start: var(--ion-margin) !important;
  }

  > ion-label {
    white-space: initial !important;
  }

  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.split-pane-side {
  max-width: 100% !important;
  flex: auto !important;
  flex-direction: column !important;
}

.cdk-overlay-pane {
  bottom: 0;
}

app-article-option-group-overlay {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--ion-color-secondary) url(#{$brandAssets}/menu_background.png) no-repeat center center/contain;
  background-origin: content-box;
  padding: 16px;

  .wrapper-upgrade {
    height: 750px;
  }
}

.display-contents {
  display: contents;
}

.display-grid {
  display: grid;
}

.label-floating {
  z-index: 4;
}

app-burger-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  cursor: pointer;
}

.underline {
  color: var(--ion-color-gold);
  text-decoration: underline;
}

ion-picker {
  touch-action: none;
}

.cc-animate {
  transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
  transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
  transform: translateY(0) !important;
}

svg {
  width: 100%;
}

.picker-opt {
  opacity: 0.3;
  font-weight: bold;
}

.picker-opt-selected {
  opacity: 1;
}

@include desktop {
  ion-picker-column {
    overflow: auto;
  }
  .picker-opts {
    top: unset !important;
    transform: none !important;
    pointer-events: none;
  }
  .picker-opt {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
  .picker-opt-selected {
    transition: 100ms;
    font-size: 30px;
  }
}

.delivery-not-available-modal .modal-wrapper {
  height: 260px;
  min-height: unset !important;
  bottom: 0;
  position: absolute;
  display: block;
  @include desktop {
    height: 220px;
    bottom: unset !important;
  }
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.align-center {
  text-align: center;
}

.searchbar-input.sc-ion-searchbar-md {
  box-shadow: unset !important;
  border-radius: 5px !important;
  background: white;
  --background: white;
}
ion-searchbar ion-icon {
  left: 10px !important;
  right: auto !important;
}
.sc-ion-searchbar-md-h {
  padding-inline-start: 11px !important;
}
.searchbar-clear-button.sc-ion-searchbar-md {
  display: block !important;
}
.searchbar-clear-icon.sc-ion-searchbar-md {
  width: 14px !important;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  width: 25px !important;
  top: 10px;
}

ion-header:after {
  display: none;
}

app-my-orders {
  ion-header {
    background: var(--ion-color-main);
  }
}
ion-modal {
  ion-backdrop {
    background: rgba(5, 5, 5, 0.35);
  }
}
ion-modal.auto-height {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 95vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.align-bottom {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }
}

ion-modal.align-bottom-only-mobile {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    @include desktop {
      bottom: unset !important;
    }
  }
}

ion-modal.allow-gps-modal .modal-wrapper {
  border-radius: 10px;
  @include mobile {
    width: 85vw;
  }
}
.only-next-day-modal .modal-wrapper {
  @include mobile {
    width: 85vw;
  }
}

.select-venue-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}

.light-grey-transparent {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

ion-item {
  --background-focused-opacity: 0;
}

ion-item {
  --ripple-color: transparent !important;
  --background-hover: transparent !important;
}

app-promo-code {
  display: block;
}

.sidebar {
  ion-slides {
    display: contents;

    > div {
      display: contents !important;
    }
  }

  ion-slide {
    display: contents;
  }
}

.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.cc_dialog {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 16px !important;
  background: white !important;
  color: var(--ion-color-black);

  p {
    font-size: 10px !important;
    color: var(--ion-color-black) !important;
  }

  h1 {
    font-size: 16px;
    color: var(--ion-color-black) !important;
  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.checkbox_cookie_consent {
  display: none;
}

.cc_cp_f_save {
  button {
    background: var(--ion-color-main) !important;
    color: white !important;
  }
}

.cc_b_cp {
  border-radius: 50px !important;
  color: var(--ion-color-primary) !important;
  border: 2px solid var(--ion-color-primary) !important;
  background-color: white !important;
}

.cc_b_ok {
  color: white !important;
  background: var(--ion-color-primary) !important;
  border: 2px solid var(--ion-color-primary) !important;
  border-radius: 50px !important;
}

app-add-to-home {
  position: absolute;
  bottom: 0;
}

ion-row.center-vertical {
  > * {
    align-self: center;
  }
}

.dashed-border {
  border: 2px dashed var(--ion-color-primary);
}

.tip-picker {
  .picker-columns {
    width: 30%;
    min-width: 200px;
    margin: 0 auto;

    ion-picker-column > .picker-opts > .picker-opt-selected {
      font-size: 25px;
      color: var(--ion-color-primary);
    }

    .sign-column {
      width: 30px;
    }

    .comma-column {
      width: 30px;
    }
  }
}

.padding-5 {
  padding: 5px;
}

.max-height-100 {
  max-height: 100%;
}

.content-only {
  color: var(--ion-color-primary);
  width: auto;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0 5px;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
  --background: none;
}

app-scan-qr {
  background: transparent;
  backdrop-filter: blur(20px);
}
app-order > ion-content {
  background: transparent;
  --background: transparent;
}
app-order-content > ion-content {
  background: transparent;
  --background: transparent;
}
app-modal-info {
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

app-menu-modal {
  background: var(--ion-color-primary) !important;
  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-note-article-modal {
  background: white !important;
}
app-email-confirmation,
app-email-action {
  background: url("#{$brandAssets}/modal-circle.svg") no-repeat right bottom / 30% !important;
}
app-payment-modal {
  ion-content {
    --background: transparent !important;
  }
  h1 {
    margin-left: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
    color: var(--ion-color-main) !important;
    font-family: AppFont, sans-serif;
    font-weight: 500;
    margin-top: 30px !important;
    text-transform: uppercase !important;
    display: none;
  }
  .payment-input {
    --payment-modal-item-background: white !important;
    border-radius: 0px !important;
    background-color: white;
    border: 0.5px solid #e5e5e5 !important;
    box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02) !important;
    border-radius: 7px !important;
  }
  .wpwl-label {
    color: var(--ion-color-second);
    font-family: SansFont, sans-serif;
  }
  .wpwl-form {
    background: white;
    padding: 10px;
  }
  .wpwl-wrapper {
    border-radius: 0px !important;
    overflow: hidden;
    background-color: white;
  }

  ion-item {
    border: 0.5px solid #e5e5e5;
    box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);
    border-radius: 7px;
    ion-label {
      font-family: AppFont, sans-serif !important;
      color: #6b6b6b !important;
    }
    ion-icon {
      zoom: 2 !important;
    }
  }
  .wpwl-button-pay {
    float: none !important;
    width: 100%;
    background: var(--ion-color-primary);
    border-radius: 0px;
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
    font-family: AppFontSemiBold, sans-serif;
    text-transform: uppercase;
    padding: 15px;
    border-radius: 28px;
  }
}
.close-icon {
  zoom: 0.6 !important;
}
app-home {
  background: url(./assets/sashimi/home_background.png) no-repeat center center / 100% !important;

  @include mobile {
    background: url(./assets/sashimi/home_background.png) no-repeat center center / 100% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-checkout-modal {
  // @include desktop {
  //   background: var(--ion-color-light) url(#{$brandAssets}/home_background.png) no-repeat left top / 40% !important;
  // }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-check-delivery-radius-modal {
  @include desktop {
    background: white url(#{$brandAssets}/home_background.png) no-repeat left top / 40% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-image-toolbar {
  background: url(#{$brandAssets}/home-back-desktop1.jpg) no-repeat center top / 10% !important;
  background-size: cover !important;
}

.container {
  position: relative;
  margin-bottom: 20px;
  ion-label {
    position: absolute;
    top: -7px;
    background: white;
    z-index: 999;
    left: 14px;
    padding: 0px 4px;
    font-size: 11px;
    color: rgba(34, 34, 34, 0.7);
    font-family: AppFont;
  }
}
.input-error {
  --color: red;
  --placeholder-color: red;
  margin-bottom: 0 !important;
}

.error-text {
  margin: 4px 0;
  width: 100%;
  color: red;
  font-size: x-small;
}
.wpwl-control {
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  padding: 4px 8px;
  margin-bottom: 15px;
}

.wpwl-label {
  font-size: 12px;
  padding: 0px;
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 999;
  background: white;
  padding: 1px;
}

ion-button,
.add-to-order-button {
  cursor: pointer;
  --background: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary-shade);
  --background-hover: var(--ion-color-primary-shade);
  --riple-color: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-contrast);
  min-height: 54px;
  font-family: AppFontBold, sans-serif;
  text-align: center;
  --border-radius: 28px;
  letter-spacing: 0;
  --box-shadow: none;
  font-size: 16px;
  margin: 0;
  border-radius: 28px;
  text-transform: capitalize;
  color: white;
  &.button-small {
    font-size: small;
    min-height: auto;

    ion-icon {
      margin-right: 4px;
    }
  }

  &.invalid {
    background: darkgrey;
    color: grey;
  }
  &.white {
    --background: white;
    color: var(--ion-color-primary);
    --background-activated: #e5ebea;
    --background-hover: #e5ebea;
    --background-focused: #e5ebea;
    --riple-color: white;
  }
  &.content-only {
    width: auto;
    height: auto;
    border: 0;
    margin: 0;
    padding: 0 5px;
    --padding-start: 0;
    --padding-end: 0;
    --box-shadow: none;
    --background: none;

    ion-icon {
      margin-right: 0;
    }
  }
}
.select-gender {
  .alert-wrapper {
    min-width: 350px;
  }
  .alert-title {
    font-family: AppFontBold, sans-serif;
    margin: 10px 0px;
  }
  .alert-radio-group {
    border-top: 0.5px solid rgba(178, 192, 192, 0.5);
    border-bottom: none;
  }
  .alert-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;
    button {
      height: 55px;
      font-family: AppFontBold, sans-serif;
      text-align: center;
      width: 47%;
      border-radius: 28px;
      .alert-button-inner {
        justify-content: center;
      }
    }
    button:first-child {
      border: 2px solid var(--ion-color-primary);
      color: var(--ion-color-primary);
    }
    button:last-child {
      background: var(--ion-color-primary);
      color: white;
    }
  }
}
.checkout-modal-view {
  .modal-wrapper {
    @include desktop {
      min-width: 1100px;
    }
  }
}
.delivery-modal {
  .modal-wrapper {
    @include desktop {
      min-width: 644px;
    }
  }
}
.block-venues {
  background: white !important;
  padding: 10px 20px !important;
}
.padding-modal-items-select {
  padding: 5px 25px;
}
app-map-venue {
  ion-spinner {
    color: var(--ion-color-primary) !important;
  }

  .title {
    margin: 10px 0px 10px 25px !important;
    font-family: AppFontBoldAlt, sans-serif !important;
    text-transform: capitalize !important;
  }

  .map-toggle {
    padding: 15px 25px !important;
  }

  .mode-icon {
    &.selected {
      background-color: #222 !important;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--ion-color-medium);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-medium);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}
.error_container {
  height: 15px;
  position: relative;
  margin-bottom: 5px;
  ion-note {
    font-size: 10px !important;
  }
}

.error_container_highter {
  height: 48px;
}

.input-error {
  position: absolute;
  opacity: 0;
  margin-left: 10px;
  color: rgb(119, 25, 25);
}

.input_info {
  position: absolute;
  opacity: 0;
  margin-left: 10px;
  color: #6b6b6b;
}

.ion-invalid.ion-info {
  --highlight-background: #6b6b6b;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s;
}
.ionic-tel-input-modal {
  ion-backdrop {
    background: rgba(5, 5, 5, 0.35);
    opacity: 0.5 !important;
    width: 100%;
    height: 100%;
  }
  .modal-wrapper {
    height: 62vh;
    width: 80%;
    border-radius: 8px;
    @include desktop {
      width: 50%;
    }
    .button-clear {
      color: var(--ion-color-black);
      font-family: AppFontBold, sans-serif;
      text-transform: uppercase;
    }
    .title-default {
      display: none;
    }
  }
}

.ion-intl-tel-input-number {
  padding-bottom: 0px;
  padding-left: 35px;

  .native-input.sc-ion-input-md {
    font-size: 14px !important;
    font-family: AppFontMedium;
    color: var(--ion-color-black);
  }
}

.ionic-selectable-icon-inner {
  border-style: none !important;
  background: url(./assets/sashimi/arrow_tel.svg) no-repeat center center/100%;
  height: 4px;
  width: 7px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 50px;
    width: 1px;
    background-color: #ececec;
    top: -22px;
    left: 35px;
  }
}

.ionic-selectable-value-item {
  span {
    font-size: 14px;
  }
}

.mat-snack-bar-container {
  background-color: grey;

  span {
    font-family: AppFontMedium;
    color: white;
  }
}

.alert-button {
  span {
    color: white;
    font-family: AppFontMedium;
  }
}

.alert-button-role-cancel {
  span {
    color: var(--ion-color-primary);
    font-family: AppFontMedium;
  }
}
.map-toggle {
  @include mobile {
    border-bottom: 1px solid #ececec;
  }
}
.cluster {
  display: flex;
  justify-content: center;

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: url(/assets/icon/maps_marker_cluster.svg);
  }

  > div {
    display: contents;
    line-height: 2rem !important;

    > span {
      z-index: 2;
      color: var(--ion-color-primary);
      position: absolute;
      top: 3px;
      font-size: 15px;
      font-family: AppFontMedium sans-serif !important;
    }
  }
}
