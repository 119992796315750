@import "../theme/mixins";

$brand: 'sushisupply';
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #2F333A;
  --ion-color-second: rgba(47, 51, 58, 0.7);
  --ion-color-desc: #6b6b6b;

}

@font-face {
  font-family: AppFont;
  src: url('../assets/font/MuseoSansCyrl-300.ttf')
}
@font-face {
  font-family: AppFontBold;
  src: url('../assets/font/SourceSansPro-Bold.ttf')
}
@font-face {
  font-family: AppFontBoldAlt;
  src: url('../assets/font/SourceSansPro-Bold.ttf')
}
@font-face {
  font-family: AppFontMedium;
  src: url('../assets/font/MuseoSansCyrl-500.ttf')
}
@font-face {
  font-family: AppFontToggler;
  src: url('../assets/font/Sunny-Skies.otf')
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-family: AppFontBold, sans-serif;
  color: var(--ion-color-black);
}

p,
span {
  margin: 0;
  padding: 0;
  font-family: AppFont, sans-serif;
  font-size: 16px;
  color: var(--ion-color-black);
}
